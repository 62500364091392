import { CONSTANTS } from '../api';
let initState = {
    fenceDataList: [],
    latest: {}
}

export function fencedata(state = initState, action) {
    //console.log(action)
    let __state = { ...state }
    switch (action.type) {
        case CONSTANTS.FENCEDATA.DATA:
            return {
                fenceDataList: action.result
            };
        case CONSTANTS.FENCEDATA.LATEST_READING:
            // return { ...state, latest: action.data };
                __state = {...state}
                __state.data = action.result
            return __state

            case CONSTANTS.FENCEDATA.ALLDATABYID:
                // return { ...state, latest: action.data };
                    __state = {...state}
                    __state.data = action.result
                return __state

       
        
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}
