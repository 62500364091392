import { CONSTANTS } from "../api";
let initState = {
  data: [],
  dataList: [],
  dataRegistered: [],
  dataListRegistered: [],
  dataById: [],
  latest: {},
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1,
  },
  paginationHistory: {
    itemPerPage: 5,
    currentPage: 1,
    totalPage: 1,
  },
  searchValue: "",
  dataSearch: [],
};

export function devicedata(state = initState, action) {
  //console.log(action)
  let __state = { ...state };
  switch (action.type) {
    case CONSTANTS.DEVICEDATA.DATA:
      return {
        data: action.result,
      };
    case CONSTANTS.DEVICEDATA.LATEST_READING:
      // return { ...state, latest: action.data };

      let _data = [];
      for (let i = 0; i < action.result.length; i++) {
        if (i < 10) {
          _data.push(action.result[i]);
        }
      }
      // console.log(_data);

      let dataRegistered = [];
      for (let a = 0; a < action.result.length; a++) {
        if (
          action.result[a].latitude !== null &&
          action.result[a].longitude !== null
        ) {
          dataRegistered.push(action.result[a]);
        }
      }

      let dataListRegistered = [];
      for (let i = 0; i < dataRegistered.length; i++) {
        if (i < 5) {
          dataListRegistered.push(dataRegistered[i]);
        }
      }

      let registered = 0;
      let unregistered = 0;
      let OBDtrackerRegVeh = 0;
      let GPStrackerRegVeh = 0;
      let OBDtrackerUnregVeh = 0;
      let GPStrackerUnregVeh = 0;
      let assignedFence = 0;
      action.result.map((items) => {
        // return{
        //     ...console.log(items),
        if (items.latitude === null) {
          items.registered = false;
          unregistered++;
          if (items.Device.deviceType === "On-Board Diagnostic") {
            OBDtrackerUnregVeh++;
          } else if (items.Device.deviceType === "GPS Tracker") {
            GPStrackerUnregVeh++;
          }
        } else {
          items.registered = true;
          registered++;
          if (items.Device.deviceType === "On-Board Diagnostic") {
            OBDtrackerRegVeh++;
          } else if (items.Device.deviceType === "GPS Tracker") {
            GPStrackerRegVeh++;
          }
        }

        if (items.Device.deviceFence !== "-") {
          assignedFence++;
        }
      });
      console.log(action.result);
      // console.log(__state,state)
      __state.data = action.result;

      __state.data.registered = registered;
      __state.data.unregistered = unregistered;
      __state.data.assignedFence = assignedFence;
      __state.data.OBDtrackerRegVeh = OBDtrackerRegVeh;
      __state.data.GPStrackerRegVeh = GPStrackerRegVeh;
      __state.data.OBDtrackerUnregVeh = OBDtrackerUnregVeh;
      __state.data.GPStrackerUnregVeh = GPStrackerUnregVeh;

      console.log("check _data", _data);

      __state = {
        ...state,
        data: action.result,
        dataList: _data,
        dataRegistered: dataRegistered,
        dataListRegistered: dataListRegistered,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(
            action.result.length / state.pagination.itemPerPage
          ),
        },
        paginationHistory: {
          itemPerPage: 5,
          currentPage: 1,
          totalPage: Math.ceil(
            dataRegistered.length / state.paginationHistory.itemPerPage
          ),
        },
        error: null,
      };

      console.log("check _state", __state);

      return __state;

    case CONSTANTS.DEVICEDATA.DEVICEDATA_SEARCH:
      __state = { ...state };
      __state.dataList = [];
      __state.dataSearch = [];

      __state.pagination.currentPage = 1;
      __state.pagination.searchValue = action.result;
      state.data.forEach((element) => {
        console.log(element);
        if (
          element.Device.ownerName.includes(action.result) ||
          element.Device.vehiclePlateNum.includes(action.result) ||
          element.Device.vehicleModel.includes(action.result) ||
          element.Device.vehicleBrand.includes(action.result)
        ) {
          __state.dataSearch.push(element);
        }
      });

      __state.pagination.totalPage = Math.ceil(
        __state.dataSearch.length / state.pagination.itemPerPage
      );

      let statePage = 0;
      for (let i = 0; i < __state.dataSearch.length; i++) {
        if (statePage < state.pagination.itemPerPage) {
          __state.dataList.push(__state.dataSearch[i]);
          statePage++;
        }
      }

      state = {
        ...__state,
      };
      return state;
      break;

    case CONSTANTS.DEVICEDATA.LOGS_COUNT_CHANGE:
      __state = { ...state };
      __state.pagination.itemPerPage = action.result;
      __state.pagination.currentPage = 1;
      __state.pagination.totalPage = Math.ceil(
        __state.data.length / action.result
      );

      __state.dataList = [];
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.result) {
          __state.dataList.push(__state.data[i]);
        }
      }

      state = {
        ...__state,
      };
      return state;

    case CONSTANTS.DEVICEDATA.HISTORY.LOGS_COUNT_CHANGE: // yg ni
      __state = { ...state };
      console.log(__state, action.result);
      __state.paginationHistory.itemPerPage = action.result;
      __state.paginationHistory.currentPage = 1;
      __state.paginationHistory.totalPage = Math.ceil(
        __state.dataRegistered.length / action.result
      );

      __state.dataListRegistered = [];
      for (let i = 0; i < __state.dataRegistered.length; i++) {
        if (i < action.result) {
          __state.dataListRegistered.push(__state.dataRegistered[i]);
        }
      }

      state = {
        ...__state,
      };
      return state;

    case CONSTANTS.DEVICEDATA.LOGS_PAGE_CHANGE:
      __state = { ...state };
      __state.pagination.currentPage = action.result;

      __state.dataList = [];
      let _j = 0;
      for (
        let i = (action.result - 1) * state.pagination.itemPerPage;
        i < __state.data.length;
        i++
      ) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i]);
          _j++;
        }
      }

      state = {
        ...__state,
      };
      return state;

    case CONSTANTS.DEVICEDATA.HISTORY.LOGS_PAGE_CHANGE: // yg ni
      __state = { ...state };
      __state.paginationHistory.currentPage = action.result;

      __state.dataListRegistered = [];
      let HlogPageChange = 0;
      for (
        let i = (action.result - 1) * state.paginationHistory.itemPerPage;
        i < __state.dataRegistered.length;
        i++
      ) {
        if (HlogPageChange < state.paginationHistory.itemPerPage) {
          __state.dataListRegistered.push(__state.dataRegistered[i]);
          HlogPageChange++;
        }
      }

      state = {
        ...__state,
      };
      return state;

    case CONSTANTS.DEVICEDATA.ALLDATABYID:
      // return { ...state, latest: action.data };
      __state = { ...state };
      __state.dataById = action.result;
      return __state;



    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
}
