var API_ROUTE = '', MQTT_URL = ''
const dev = false;

if (dev) { 
    API_ROUTE = 'http://' + window.location.hostname + ':3034/api'
    MQTT_URL = 'ws://' + window.location.hostname + ':3890'
} else {
    API_ROUTE = 'https://' + window.location.hostname + '/api'
    MQTT_URL = 'wss://' + window.location.hostname + '/ws'
}

export const SERVER = {
    API: {
        Login: API_ROUTE + '/AppUsers/login',
        Logout: API_ROUTE + '/AppUsers/logout',
        ChangePassword: API_ROUTE + '/AppUsers/change-password',
        ResetPassword: API_ROUTE + '/AppUsers/reset/password',
        resetPasswordByAdmin: API_ROUTE + '/AppUsers/resetpasswordbyadmin',
        listUserbyEmail: (email) => { return API_ROUTE + '/AppUsers/resetpassword/' + email },


        AppUser: {
            MAIN: API_ROUTE + 'AppUsers',

            RegisterAdmin: API_ROUTE + '/AppUsers/register/admin',
            RegisterManager: API_ROUTE + '/AppUsers/register/manager',
            UpdateManager: API_ROUTE + '/AppUsers/update/manager',
            DeleteManager: API_ROUTE + '/AppUsers/delete/manager',
            ListManager: API_ROUTE + '/AppUsers/list/managers', //made changes here /AppUsers/list/managers
            ListUser: API_ROUTE + '/AppUsers/list/AllUsers',
            RegisterUser: API_ROUTE + '/AppUsers/register/user',

            UpdateByAdmin: API_ROUTE + '/AppUsers/updatebyadmin',
            UpdateByManager: API_ROUTE + '/AppUsers/updatebymanager',

            DeleteByAdmin: API_ROUTE + '/AppUsers/deletebyadmin',
            DeleteByManager: API_ROUTE + '/AppUsers/deletebymanager',

            ChangePassword: API_ROUTE + '/AppUsers/change-password',
            ResetPassword: API_ROUTE + '/AppUsers/resetpasswordbyadmin',


            changeProfile: (id) => { return API_ROUTE + '/AppUsers/changeProfile/' + id },
            UpdateById: (id) => { return API_ROUTE + '/AppUsers/update/' + id },
            RemoveUser: (id) => { return API_ROUTE + '/AppUsers/remove/' + id },

            // UploadDevicePicture: API_ROUTE + '/Stores/PictureDevice/upload',
            UploadUserPicture: API_ROUTE + '/Stores/PictureUser/upload',

            // GetDevicePicture: (fileName) => { return API_ROUTE + '/Stores/PictureDevice/download/' + fileName },
            GetUserPicture: (fileName) => { return API_ROUTE + '/Stores/PictureUser/download/' + fileName },
        },

        Device: {
            Device: API_ROUTE + '/Devices',
            DeviceList: API_ROUTE + '/Devices/list',
            Statistic: API_ROUTE + '/Devices/devicestatistic',
            Register: API_ROUTE + '/Devices/register',
            Delete: API_ROUTE + '/Devices/',
            Update: API_ROUTE + '/Devices/update',
            UploadDevicePicture: API_ROUTE + '/Stores/PictureDevice/upload',

            UpdateById: (id) => { return API_ROUTE + '/Devices/updateDevice/' + id },
            RemoveDevice: (id) => { return API_ROUTE + '/Devices/removeDevice/' + id },
            GetDevicePicture: (fileName) => { return API_ROUTE + '/Stores/PictureDevice/download/' + fileName },
        },

        DeviceData: {
            MAIN: API_ROUTE + '/DeviceData',
            LATEST: API_ROUTE + '/DeviceData/latest',
            LatestData: API_ROUTE + '/DeviceData/getLatestDataByDevice',
            GetDeviceDataByDate : API_ROUTE + '/DeviceData/getDeviceDataByDate',

            getAllDataByID: (id) => { return API_ROUTE + '/DeviceData/getAllDataByID/' + id },
          
        },

        Fence: {
            Fence: API_ROUTE + '/Fences',
            FenceList: API_ROUTE + '/Fences/list', // used
            Statistic: API_ROUTE + '/Fences/fencestatistic',
            Register: API_ROUTE + '/Fences/register',
            Delete: API_ROUTE + '/Fences/',
            Update: API_ROUTE + '/Fences/update',
            UploadFencePicture: API_ROUTE + '/Stores/PictureFence/upload',

            UpdateById: (id) => { return API_ROUTE + '/Fences/updateFence/' + id },
            RemoveFence: (id) => { return API_ROUTE + '/Fences/removeFence/' + id },
            GetFencePicture: (fileName) => { return API_ROUTE + '/Stores/PictureFence/download/' + fileName },
        },

        FenceData: {
            // MAIN: API_ROUTE + '/FenceData',
            // LATEST: API_ROUTE + '/FenceData/latest',
            // LatestData: API_ROUTE + '/FenceData/getLatestDataByDevice',
            FenceDataList: API_ROUTE + '/FenceData/getAllData',
            // GetFenceDataByDate : API_ROUTE + '/FenceData/getDeviceDataByDate',

            // getAllDataByID: (id) => { return API_ROUTE + '/DeviceData/getAllDataByID/' + id },
          
        },

        History: {
            MAIN: API_ROUTE + '/HistoryData'
            // Update: MAIN+'/api/History/update/info'
        },

    },
    MQTT: {
        URL: MQTT_URL,
        User: 'myav_dash',
        Pass: 'asdh@453bia&2sfeeYq3rs'
    }
}