import { CONSTANTS } from "../api";

let initState = {
  data: [],
  graph: null,
  message: "",
  notification: [],
  liveLocation: [],
  listErrSystem: [],
  errorSystem: {
    id: 0,
    show: false,
    types: "",
    value: "",
    resolve: false,
    datetime: "",
  },
};

export function dashboard(state = initState, action) {
  //console.log(action)
  // let _data = []
  let __state = { ...state };
  switch (action.type) {
    case CONSTANTS.DASHBOARD.SUCCESS:
      return {
        data: action.result,
        graph: [...state.graph],
      };
    case CONSTANTS.DASHBOARD.FAILURE:
      return {
        message: action.message,
        data: [...state.data],
        graph: [...state.graph],
      };
    case CONSTANTS.DASHBOARD.GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.result,
      };
    case CONSTANTS.DASHBOARD.GRAPH_FAILURE:
      return {
        message: action.message,
        graph: [...state.graph],
      };
    case CONSTANTS.DASHBOARD.NOTIFICATION:
      return { ...state, notification: action.data };

    case CONSTANTS.DASHBOARD.ERRORSYSTEM:
      // console.log(action.data)
      __state.listErrSystem.push(action.data);
      __state.errorSystem = {
        id: __state.listErrSystem[0].id,
        show: true,
        types: __state.listErrSystem[0].types,
        value: __state.listErrSystem[0].value,
        datetime: __state.listErrSystem[0].datetime,
        resolve: __state.listErrSystem[0].resolve,
      };

      return __state;
    case CONSTANTS.DASHBOARD.ERRORSYSTEM_RESET:
      __state.listErrSystem.shift();
      if (__state.listErrSystem.length === 0) {
        __state.errorSystem = initState.errorSystem;
      } else {
        __state.errorSystem = {
          id: __state.listErrSystem[0].id,
          show: true,
          types: __state.listErrSystem[0].types,
          value: __state.listErrSystem[0].value,
          datetime: __state.listErrSystem[0].datetime,
          resolve: __state.listErrSystem[0].resolve,
        };
      }
      return __state;

    case CONSTANTS.DASHBOARD.LATEST_DATA_SUCCESS:
      __state = { ...state };
      __state.data = action.result; // first data

      let _data = [];
      for (let a = 0; a < action.result.length; a++) {
        if (
          // undected
          action.result[a].batteryVoltage < 1
        ) {
          _data.push((action.result[a].status = "Undetected"));
        } else if (
          (action.result[a].engineSpeed === 0 ||
            action.result[a].speed === 0) &&
          action.result[a].tripId !== 0
        ) {
          // Inactive
          _data.push((action.result[a].status = "Inactive"));
        } else {
          // active
          _data.push((action.result[a].status = "Active"));
        }

        if(__state.liveLocation.length !== 0){
            // console.log(__state.liveLocation.Device.id)
            // console.log(action.result[a].Device.id)
            if(__state.liveLocation.Device.id === action.result[a].Device.id){
                console.log(action.result[a])
                __state.liveLocation = action.result[a]
                __state.liveLocation.pointer = true

            //     console.log("result",action.result.latitude)
            // console.log("liveLocation",__state.liveLocation.latitude)
            }
            
        }
      }
      __state.data = action.result;
      console.log(__state.liveLocation)

      

      return __state;

    case CONSTANTS.DASHBOARD.LIVE_LOCATION_VEHICLE:
      console.log("+++++++LIVE LOCATION READ++++",action.result);
      return {
        ...state,
        liveLocation: action.result,
      };

    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
}
