import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { devicedata } from './devicedata'
import { fencedata } from './fencedata'
import { device } from './device'
import { fence } from './fence'
import { users } from './users'

const rootReducer = combineReducers({
  dashboard,
  devicedata,
  device,
  fence,
  fencedata,
  users
});

export default rootReducer;