import { CONSTANTS } from "../api";
let initState = {
  data: [],
  latest: {},
  pagination: {
    itemPerPage: 2,
    currentPage: 1,
    totalPage: 1,
  },
  searchValue: "",
};

export function device(state = initState, action) {
  //console.log(action)
  let __state = { ...state };
  switch (action.type) {
    case CONSTANTS.DEVICE.LIST_SUCCESS:
      return {
        data: action.result,
      };
    case CONSTANTS.DEVICE.LATEST_READING:
      // return { ...state, latest: action.data };
      __state = { ...state };
      __state.data = action.result;

      let _data = [];
      for (let i = 0; i < action.result.length; i++) {
        if (i < 2) {
          _data.push(action.result[i]);
        }
      }

      console.log("check _data", _data);

      __state = {
        ...state,
        data: action.result,
        dataList: _data,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(
            action.result.length / state.pagination.itemPerPage
          ),
        },
        error: null,
      };

      console.log("check _state", __state);

      return __state;
      

    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
}
